import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import IndexHero from "../components/Hero/IndexHero";
import Layout from "../components/Layout/Layout";
import SEO from "../components/SEO/SEO";
import SliceZone from "../components/SliceZone/SliceZone";


export const query = graphql`
  query Homepage {
    allPrismicHomepage {
      edges {
        node {
          data {
            meta_title {
              text
            }
            meta_description {
              text
            }
            benefits {
              benefits_item {
                text
              }
            }
            hero_image_desktop {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1920
                    quality: 80
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            hero_image_mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1025
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            main_headline {
              text
            }
            supporting_headline {
              text
            }
            supporting_headline_link {
              link_type
              url
            }
            body {
              ... on PrismicHomepageBodyText {
                primary {
                  section_title {
                    text
                  }
                  section_page_link {
                    url
                    link_type
                  }
                  section_description {
                    text
                  }
                  background_color
                  text_color
                  section_page_link_text {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicHomepageBodyProductGallery {
                items {
                  overlay_color
                  product_link {
                    url
                    link_type
                    document {
                      ... on PrismicProduct {
                        data {
                          body {
                            ... on PrismicProductBodyProductVariant {
                              items {
                                product_variant_title {
                                  text
                                }
                              }
                            }
                          }
                          product_image {
                            localFile {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 1000
                                  quality: 80
                                  placeholder: TRACED_SVG
                                  formats: [AUTO, WEBP, AVIF]
                                )
                              }
                            }
                          }
                          product_title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                primary {
                  text_color
                }
                slice_type
              }
              ... on PrismicHomepageBodyForm {
                items {
                  form_type {
                    text
                  }
                  form_placeholder {
                    text
                  }
                  form_name {
                    text
                  }
                  form_label {
                    text
                  }
                }
                primary {
                  background_color
                  section_description {
                    text
                  }
                  section_title {
                    text
                  }
                  text_color
                }
                slice_type
              }
              ... on PrismicHomepageBodyProcess {
                id
                primary {
                  background_color
                  icon_color
                  section_description {
                    text
                  }
                  section_title {
                    text
                  }
                  text_color
                }
                items {
                  process_stage_title {
                    text
                  }
                  process_stage_description {
                    text
                  }
                  process_stage_icon {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 120
                          quality: 100
                          placeholder: TRACED_SVG
                          formats: [PNG]
                        )
                      }
                    }
                  }
                }
                slice_type
              }
              ... on PrismicHomepageBodyCallToAction {
                primary {
                  text_color
                  background_color
                  action_text {
                    text
                  }
                  action_number {
                    text
                  }
                  action_link {
                    link_type
                    url
                  }
                }
                slice_type
              }
              ... on PrismicHomepageBodyArticleList {
                primary {
                  background_color
                  text_color
                  section_title {
                    text
                  }
                  section_page_link {
                    url
                    link_type
                  }
                  section_description {
                    text
                  }
                }
                items {
                  background_color
                  text_color
                  articles_to_link {
                    url
                    link_type
                    document {
                      ... on PrismicArticle {
                        data {
                          article_image {
                            localFile {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 600
                                  quality: 80
                                  placeholder: BLURRED
                                  formats: [AUTO, WEBP, AVIF]
                                )
                              }
                            }
                          }
                          article_post_date(
                            formatString: "MMMM DD, YYYY"
                          )
                          article_title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                slice_type
              }
              ... on PrismicHomepageBodyTestimonials {
                items {
                  testimonial_text {
                    text
                  }
                  testimonial_author {
                    text
                  }
                  testimonial_rating {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 200
                          quality: 80
                          placeholder: TRACED_SVG
                          formats: [PNG]
                        )
                      }
                    }
                  }
                }
                primary {
                  section_title {
                    text
                  }
                  section_description {
                    text
                  }
                  background_color
                  text_color
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`;


const IndexPage = ({
  data
}) => {
  if (!data) return null;

  const document = data.allPrismicHomepage.edges[0].node.data;

  const homepageMeta = {
    metaTitle: document.meta_title.text,
    metaDescription: document.meta_description.text,
  };

  const homepageContent = {
    benefits: document.benefits,
    heroImageDesktop: getImage(document.hero_image_desktop.localFile),
    heroImageMobile: getImage(document.hero_image_mobile.localFile),
    mainHeadline: document.main_headline.text,
    supportingHeadline: document.supporting_headline.text,
    supportingHeadlineLink: document.supporting_headline_link,
  };

  return (
    <Layout
      isLandingPage={ true }
      headerTextColor="#FFFFFF"
    >
      <SEO
        description={ homepageMeta.metaDescription }
      />

      <IndexHero
        heroImageMobile={ homepageContent.heroImageMobile }
        heroImageDesktop={ homepageContent.heroImageDesktop }
        mainHeadline={ homepageContent.mainHeadline }
        supportingHeadline={ homepageContent.supportingHeadline }
        supportingHeadlineLink={ homepageContent.supportingHeadlineLink }
        benefits={ homepageContent.benefits }
      />

      <SliceZone sliceZone={ document.body } />
    </Layout>
  )
}

export default IndexPage;
