import * as React from "react";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import styled, { keyframes } from "styled-components";

import {
  COLORS,
  CONTAINERS,
  PADDINGS,
  SCREENS,
  SVGS
} from "../../utils/constants";

import ButtonBare from "../Button/ButtonBare";


const ticker = ( scrollLength ) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-${ scrollLength }px, 0, 0);
  }
`;

const headlineMain = () => keyframes`
  0%, 25%, 50%, 75%, 100% {
    width: 0;
  }

  5%, 20% {
    width: 100%;
  }
`;

const headlineBlinds = () => keyframes`
  0%, 25%, 50%, 75%, 100% {
    width: 0;
  }

  30%, 45% {
    width: 100%;
  }
`;

const headlineShades = () => keyframes`
  0%, 25%, 50%, 75%, 100% {
    width: 0;
  }

  55%, 70% {
    width: 100%;
  }
`;

const headlineDrapes = () => keyframes`
  0%, 25%, 50%, 75%, 100% {
    width: 0;
  }

  80%, 95% {
    width: 100%;
  }
`;

const Section = styled.section`
  position: relative;
  width: 100%;
  height: calc(100vw * 1.295);
  overflow-x: hidden;

  @media (min-width: ${ SCREENS.laptop }) {
    height: calc(100vw / 2);
  }
`;

const HeroImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HeroWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    ${ COLORS.primary.o6 },
    ${ COLORS.primary.o6 }
  );
`;

const Container = styled.div`
  position: relative;
  width: ${ CONTAINERS.mobile };
  height: 100%;
  margin: 0 auto;
  
  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
  }
`;

const HeadlineContainer = styled.div`
  position: absolute;
  top: 35%;

  @media (min-width: ${ SCREENS.laptop }) {
    top: 45%;
  }
`;

const MainHeadlineTag = styled.span`
  display: block;
`;

const AnimatedHeadlineContainer = styled.div``;

const AnimatedHeadlineMain = styled.span`
  animation: ${ headlineMain };
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 20s;
  animation-timing-function: steps(17, end);
  animation-iteration-count: infinite;
`;

const AnimatedHeadlineBlinds = styled.span`
  animation: ${ headlineBlinds };
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 20s;
  animation-timing-function: steps(17, end);
  animation-iteration-count: infinite;
`;

const AnimatedHeadlineShades = styled.span`
  animation: ${ headlineShades };
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 20s;
  animation-timing-function: steps(17, end);
  animation-iteration-count: infinite;
`;

const AnimatedHeadlineDrapes = styled.span`
  animation: ${ headlineDrapes };
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 20s;
  animation-timing-function: steps(17, end);
  animation-iteration-count: infinite;
`;

const MainHeadline = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  @media (min-width: ${ SCREENS.laptop }) {
    flex-direction: row;
  }
`;

const TickerWrapper = styled.div`
  position: absolute;
  bottom: 5%;
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
`;

const Ticker = styled.div`
  display: flex;
  box-sizing: content-box;
  animation: ${ props => ticker( (props.itemWidth) * props.numItems ) } 40s linear infinite;
`;

const BenefitsItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 256px;
`;

const BenefitsItem = styled.p`
  width: 130px;
  text-align: center;
`;


const IndexHero = ({
  heroImageMobile,
  heroImageDesktop,
  mainHeadline,
  supportingHeadline,
  supportingHeadlineLink,
  benefits
}) => {
  const sources = withArtDirection(heroImageMobile, [
    {
      image: heroImageDesktop,
      media: `(min-width: ${ SCREENS.laptop })`,
    }
  ]);

  const benefitsItems = benefits.map(item => {
    return (
      <BenefitsItemWrapper>
        <BenefitsItem>{ item.benefits_item.text }</BenefitsItem>
      </BenefitsItemWrapper>
    )
  });

  return (
    <Section>
      <HeroImage
        image={ sources }
      />
      <HeroWrapper>
        <Container>
          <HeadlineContainer>
            <MainHeadline>
              <MainHeadlineTag>Your Dream&nbsp;</MainHeadlineTag>
              <AnimatedHeadlineContainer>
                <AnimatedHeadlineMain>Space Starts Here</AnimatedHeadlineMain>
                <AnimatedHeadlineBlinds>Blinds Start Here</AnimatedHeadlineBlinds>
                <AnimatedHeadlineShades>Shades Start Here</AnimatedHeadlineShades>
                <AnimatedHeadlineDrapes>Drapes Start Here</AnimatedHeadlineDrapes>
              </AnimatedHeadlineContainer>
            </MainHeadline>
            <ButtonBare
              linkText={ supportingHeadline }
              linkDestination={ supportingHeadlineLink }
              textColor={ `#ffffff` }
              buttonMargin="1rem 0"
            />
          </HeadlineContainer>
          <TickerWrapper>
            <Ticker
              itemWidth={ 256 }
              numItems={ 5 }
            >
              { benefitsItems }
              { benefitsItems }
            </Ticker>
          </TickerWrapper>
        </Container>
      </HeroWrapper>
    </Section>
  )
}


export default IndexHero;
